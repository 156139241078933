import * as React from 'react';

function GitHubIcon(props) {
  return (
    <a
      href="https://github.com/srijans38"
      rel="noreferrer"
      target="_blank"
      aria-label="Github"
    >
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 lg:h-12 lg:w-12"
        {...props}
      >
        <title>{'GitHub'}</title>
        <rect width={48} height={48} rx={24} fill="#000" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.422 46.829A23.958 23.958 0 0124 48a23.96 23.96 0 01-7.427-1.173c1.08.13 1.48-.574 1.48-1.181 0-.265-.005-.731-.011-1.355-.007-.722-.016-1.655-.023-2.73-6.676 1.452-8.084-3.22-8.084-3.22C8.844 35.569 7.27 34.83 7.27 34.83c-2.179-1.49.166-1.46.166-1.46 2.407.17 3.675 2.475 3.675 2.475 2.141 3.67 5.617 2.61 6.985 1.995.218-1.552.838-2.61 1.524-3.21-5.33-.607-10.932-2.666-10.932-11.87 0-2.622.934-4.765 2.47-6.445-.247-.607-1.07-3.048.235-6.355 0 0 2.015-.646 6.6 2.462 1.915-.533 3.967-.799 6.009-.81 2.038.01 4.091.277 6.009.81 4.58-3.108 6.592-2.462 6.592-2.462 1.31 3.307.486 5.748.239 6.355 1.538 1.68 2.467 3.823 2.467 6.446 0 9.225-5.611 11.256-10.957 11.85.86.742 1.628 2.207 1.628 4.448 0 2.147-.013 4.016-.022 5.255-.004.613-.007 1.072-.007 1.333 0 .61.39 1.315 1.471 1.183z"
          fill="#fff"
        />
      </svg>
    </a>
  );
}

export default GitHubIcon;
