import * as React from 'react';

function InstagramIcon(props) {
  return (
    <a
      href="https://www.instagram.com/theguywithabignose/"
      rel="noreferrer"
      target="_blank"
      aria-label="Instagram"
    >
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 lg:h-12 lg:w-12"
        {...props}
      >
        <title>{'Instagram'}</title>
        <rect width={48} height={48} rx={24} fill="#262626" />
        <path
          d="M19.001 24a5 5 0 1110-.001 5 5 0 01-10 .001zm-2.703 0a7.702 7.702 0 1015.405 0 7.702 7.702 0 00-15.405 0zm13.91-8.008a1.8 1.8 0 101.8-1.799 1.8 1.8 0 00-1.8 1.8zM17.94 36.21c-1.463-.067-2.258-.31-2.786-.516a4.66 4.66 0 01-1.725-1.122 4.627 4.627 0 01-1.122-1.725c-.206-.528-.45-1.323-.516-2.785-.073-1.581-.088-2.056-.088-6.062s.016-4.48.088-6.062c.066-1.462.312-2.256.516-2.785.272-.7.597-1.2 1.122-1.726a4.621 4.621 0 011.725-1.122c.528-.205 1.323-.45 2.786-.516 1.58-.072 2.056-.087 6.06-.087 4.003 0 4.479.016 6.061.087 1.463.067 2.256.312 2.786.516.7.272 1.2.598 1.725 1.122.526.525.85 1.025 1.122 1.726.206.528.45 1.323.516 2.785.073 1.583.088 2.056.088 6.062s-.015 4.48-.088 6.062c-.066 1.462-.311 2.257-.516 2.785-.272.7-.597 1.2-1.122 1.725a4.66 4.66 0 01-1.725 1.122c-.528.206-1.323.45-2.786.516-1.58.072-2.056.087-6.061.087-4.006 0-4.48-.015-6.06-.087zm-.125-27.12c-1.596.074-2.688.327-3.64.698a7.358 7.358 0 00-2.658 1.73 7.33 7.33 0 00-1.73 2.657c-.37.953-.624 2.044-.697 3.64-.073 1.6-.09 2.111-.09 6.185 0 4.073.017 4.585.09 6.184.073 1.597.326 2.688.697 3.641a7.333 7.333 0 001.73 2.657 7.376 7.376 0 002.657 1.73c.955.371 2.045.624 3.641.697 1.6.073 2.111.091 6.185.091 4.073 0 4.584-.017 6.184-.09 1.597-.074 2.687-.327 3.64-.698a7.377 7.377 0 002.658-1.73 7.347 7.347 0 001.73-2.657c.37-.953.625-2.044.697-3.64.073-1.601.09-2.111.09-6.185 0-4.073-.017-4.585-.09-6.184-.073-1.597-.326-2.688-.697-3.641a7.376 7.376 0 00-1.73-2.657 7.34 7.34 0 00-2.656-1.73c-.955-.371-2.045-.626-3.641-.697-1.6-.073-2.11-.091-6.184-.091s-4.585.017-6.186.09z"
          fill="#fff"
        />
      </svg>
    </a>
  );
}

export default InstagramIcon;
